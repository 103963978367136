<template>
  <div>
    <h1 class="mb-0">{{ $t('auth.sign_in') }}</h1>
    <p style="color: white;">{{ $t('auth.enter_email_') }}</p>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item :active="true" id="pills-jwt-fill" aria-labelled-by="pills-jwt-tab-fill">
          <sign-in1-form></sign-in1-form>
      </tab-content-item>
    </tab-content>
  </div>
</template>

<script>
import SignIn1Form from './Forms/SignIn1Form'
export default {
  name: 'Login',
  components: { SignIn1Form },
  data: () => ({}),
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'mini.dashboard.home-2' })
    }
  }
}
</script>
