<template>
  <div class="iq-social-media" v-if="isWebApp">
    <i class="fab fa-facebook fa-2x" style="color:#0c88ef;">
      <facebook-login class="button FacebookButton"
                      appId="212584997302645"
                      @login="loginFacebook"
                      @logout="logoutFacebook"
                      @sdk-loaded="sdkLoaded">
      </facebook-login>
    </i>
      &nbsp;&nbsp;
    <i class="fab fa-google fa-2x" style="color: grey;">
      <GoogleLogin class="GoogleButton" :params="params" :onSuccess="onSuccess" :onFailure="onFailure">{{ $t('sidebar.login') }}</GoogleLogin>
    </i>
  </div>
</template>

<script>
import facebookLogin from 'facebook-login-vuejs'
import GoogleLogin from 'vue-google-login'
import Axios from 'axios'
import { API } from '@/config/api.config'
import { Capacitor } from '@capacitor/core'
export default {
  name: 'SocialLoginForm',
  components: {
    facebookLogin,
    GoogleLogin
  },
  props: ['formtype'],
  data: () => ({
    params: {
      client_id: '469947475180-13204kpb0ljgsbmavfeo4doufpeq8ur9.apps.googleusercontent.com'
    },
    isWebApp: true
  }),
  mounted () {
    this.isWebApp = Capacitor.getPlatform() === 'web'
  },
  methods: {
    onSuccess (googleUser) {
      const googleProfile = googleUser.getBasicProfile()
      console.log(googleProfile)
      const dataPost = {
        email: googleProfile.zv,
        name: googleProfile.tf,
        picture: googleProfile.BN
      }
      console.log(dataPost)
      if (this.formtype === 'business') {
        Axios.post(API.API_CONNECT_SOCIAL, dataPost)
          .then((resp) => {
            const token = resp.data.api_key
            const user = JSON.parse(resp.data.user)
            localStorage.setItem('api_token', token)
            localStorage.setItem('company_token', user.company_token)
            if (resp.isNew === true) {
              this.$router.push('/app/company')
            } else {
              this.$router.push('/app/calendar')
            }
          })
      } else {
        Axios.post(API.API_CONNECT_SOCIAL_CUSTOMER, dataPost)
          .then((resp) => {
            const token = resp.data.api_key
            const user = JSON.parse(resp.data.user)
            localStorage.setItem('customer_api_token', token)
            localStorage.setItem('customer_token', user.token)
            this.$router.push('/customer/services')
          })
      }
    },
    onFailure (data) {
    },
    getUserData () {
      this.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
        user => {
          // this.personalID = user.id
          // this.user.email = user.email
          // const theUserName = user.name.split(' ')
          // this.user.fname = theUserName[0]
          // this.user.lname = theUserName[1]
          // this.user.picture = user.picture.data.url
          console.log(user)
          const dataPost = {
            email: user.email,
            name: user.name,
            picture: user.picture.data.url
          }
          console.log(dataPost)
          if (this.formtype === 'business') {
            Axios.post(API.API_CONNECT_SOCIAL, dataPost)
              .then((resp) => {
                const token = resp.data.api_key
                const user = JSON.parse(resp.data.user)
                localStorage.setItem('api_token', token)
                localStorage.setItem('company_token', user.company_token)
                if (resp.isNew === true) {
                  this.$router.push('/app/company')
                } else {
                  this.$router.push('/app/calendar')
                }
              })
          } else {
            Axios.post(API.API_CONNECT_SOCIAL_CUSTOMER, dataPost)
              .then((resp) => {
                const token = resp.data.api_key
                const user = JSON.parse(resp.data.user)
                localStorage.setItem('customer_api_token', token)
                localStorage.setItem('customer_token', user.token)
                this.$router.push('/customer/services')
              })
          }
        }
      )
    },
    sdkLoaded (payload) {
      this.FB = payload.FB
    },
    loginFacebook () {
      this.getUserData()
    },
    logoutFacebook () {
    }
  }
}
</script>
<style>
.fa-facebook,
.fa-google {
  position: relative;
}
.FacebookButton{
  position: absolute;
  width: 50px !important;
  overflow: hidden;
  height: 50px;
  top: 0;
  left: calc( 50% - 25px );
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
}
.FacebookButton button{
  height: 50px;
}
.GoogleButton{
  position: absolute;
  width: 50px !important;
  overflow: hidden;
  height: 50px;
  top: 0;
  left: calc( 50% - 25px );
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
}
</style>
